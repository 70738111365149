<script lang="ts" setup>
import { onMounted } from 'vue'

import AppLoadingCover from '@/components/app/loading-cover/AppLoadingCover.vue'
import { useModalContainer } from '@/composables/dynamicModal.composable'
import { usePrimeVueConfig } from '@/composables/primeVueConfig.composable'
import { useThemeManager } from '@/composables/themeManager.composable.ts'
import { logBuildInformation } from '@/constants/environment.constant.ts'
import { Theme } from '@/models/theme/theme.enum.ts'
import dayjs from '@/plugins/dayjs.plugin'
import { useLanguageStore } from '@/stores/language.store'

const primeVueConfig = usePrimeVueConfig()

const languageStore = useLanguageStore()

const themeManager = useThemeManager()

const { modals } = useModalContainer()

onMounted(() => {
	logBuildInformation()
	languageStore.initialize()
	themeManager.setTheme(Theme.HOME_MAID)
	primeVueConfig.setPrimeVueConfig()
	dayjs.locale('nl')
})
</script>

<template>
	<section class="relative h-screen w-screen overflow-hidden">
		<router-view v-slot="{ Component }">
			<transition
				mode="in-out"
				name="fade"
			>
				<AppLoadingCover v-if="!Component" />
			</transition>
			<transition
				mode="in-out"
				name="fade"
			>
				<section v-if="Component">
					<component :is="Component" />

					<template
						v-for="modal in modals"
						:key="modal.id"
					>
						<component
							:is="modal.component"
							:is-open="modal.isOpen"
							v-bind="modal.attrs"
						/>
					</template>
				</section>
			</transition>
		</router-view>
	</section>
</template>
