import { Theme } from '@/models/theme/theme.enum.ts'
import { useThemeStore } from '@/stores/theme.store.ts'

export function useThemeManager(): {
	setTheme: (themeValue: Theme | null) => void
} {
	const themeStore = useThemeStore()

	function setTheme(themeValue: Theme | null): void {
		themeStore.setTheme(themeValue)
		const themeToRemove = Object.values(Theme).filter((t) => t !== themeValue)
		document.body.classList.remove(...themeToRemove)

		if (themeStore.theme === null) {
			return
		}

		document.body.classList.add(themeStore.theme)
	}

	return {
		setTheme,
	}
}
