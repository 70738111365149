import { createRouter, createWebHistory } from 'vue-router'

import routes from '@/router/routes'
import { logError } from '@/utils/logger.util'

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.onError((error: unknown) => {
	logError(`[ROUTER] Error: ${error}`)
})

export default router
