import { defineStore } from 'pinia'
import { ref } from 'vue'

import { Theme } from '@/models/theme/theme.enum.ts'

export const useThemeStore = defineStore('themeStore', () => {
	const theme = ref<Theme | null>(Theme.HOME_MAID)

	function setTheme(themeValue: Theme | null): void {
		theme.value = themeValue
	}

	return {
		theme,
		setTheme,
	}
})
