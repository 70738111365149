import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName.ts'

export const publicTermsOfServiceRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/terms-of-service/views/PublicTermsOfServiceView.vue'),
		name: ROUTE_NAME.TERM_OF_SERVICE.INDEX,
		path: 'terms-of-service',
	},
]
