import type { RouteRecordRaw } from 'vue-router'

import { customerChecklistRoutes } from '@/modules/customer-checklist/routes/customerChecklist.routes.ts'
import { publicTermsOfServiceRoutes } from '@/modules/terms-of-service/routes/publicTermsOfService.routes'
import ROUTE_NAME from '@/router/routeName'

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: ROUTE_NAME.BASE,
		component: async () => import('@/components/layout/PublicLayout.vue'),
		meta: { requiresAuth: false },
		redirect: { name: ROUTE_NAME.REDIRECT },
		children: [...publicTermsOfServiceRoutes, ...customerChecklistRoutes],
	},
	{
		name: ROUTE_NAME.REDIRECT,
		path: '/redirect',
		beforeEnter: (): void => {
			window.location.href = 'https://www.trixxo.be'
		},
		component: { template: '<div></div>' },
	},
	{
		name: ROUTE_NAME.ERROR,
		path: '/:pathMatch(.*)*',
		component: async () => import('@/views/Error404View.vue'),
	},
]

export default routes
